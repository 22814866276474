*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'uniform-pro', Times, serif;
}

.App {
  text-align: center;
  margin: 70px;
}

.logo{
  height: 110px;
  width: 180px;
  background-image: url('../public/Shelly-Uni-2.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
}

main {
  margin-top: 50px;
}

.welcomeText {
  font-size: 1.5rem;
  margin-top: 150px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.App-header {
  background-color: rgb(245, 247, 250);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(174, 191, 203);
}

.App-link {
  color: #61dafb;
}

#shellyLogo{
  width: 100px;
}

.filter-container{
  margin-top: 40px;
}

.classList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  gap: 30px;
  padding: 20px;
}

.classItem {
  display: flex;
  flex-direction: column;
  align-items:center;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  color: rgb(9, 9, 9);
  min-width: 300px;
  max-width: 450px; 
}

/* Tablets and standard displays (medium screens) */

@media (min-width: 720px) {
  .classList {
    grid-template-columns: repeat(2, 1fr);
  }
  .classItem {
    width: 400px;
  }
}

/* Large desktop displays */

@media (min-width: 1300px) {
  .classList {
    grid-template-columns: repeat(3, 1fr);
  }
  .classItem {
    width: 450px;
  }
}



.avatar {
  width: 200px;
  border-radius: 100px;
}

.nav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: rgb(245, 247, 250);
}

footer{
  padding: 20px;
  text-align: center; 
  font-size: 1.2rem;
}